<template>
  <v-row>
    <v-col
      cols="12"
      xs="12"
      class="text-right pb-0 mb-0"
    >
      <v-btn
        x-small
        elevation="10"
        class="text-xs font-weight-semibold"
        color="primary darken-2"
        :to="{ name: 'savings.clients_create_zone' }"
      >
        <font-awesome-icon
          icon="fa-solid fa-plus"
          class="pe-1"
        />
        Create a Zone
      </v-btn>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title class="mt-0 pt-0">
          <span class="me-3">({{ zone_types.length }}) List Of Zones</span>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-text v-if="loading">
          <spinner></spinner>
        </v-card-text>
        <v-data-table
          v-else
          :headers="headers"
          :items="zone_types"
          item-key="id"
          class="table-rounded"
          :items-per-page="10"
          disable-sort
          :search="search"
        >
          <!-- name -->
          <template #[`item.spot_name`]="{item}">
            {{ item.spot_name }}
          </template>
          <template #[`item.city`]="{item}">
            {{ item.city }}
          </template>
          <!-- status -->
          <template #[`item.country`]="{item}">
              {{ item.country }}
          </template>
          <template #[`item.get_date_str`]="{item}">
            {{ item.get_date_str }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {

    return {
      headers: [
        { text: 'Name', value: 'spot_name' },
        { text: 'City', value: 'city' },
        { text: 'Country', value: 'country' },
        { text: 'Date/Time', value: 'get_date_str' },
      ],
      zone_types: [],
      search: '',
      loading: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Savings | List of Zones"
      this.getZones()
  },

  methods:{
    async getZones(){
      await axios
        .get('/api/v1/manager/get/topup/zones/')
        .then(response => {
          this.zone_types = response.data  // get the data and fill into campaigns
          console.log(this.zone_types)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
    },
  },
}
</script>
