var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"text-right pb-0 mb-0",attrs:{"cols":"12","xs":"12"}},[_c('v-btn',{staticClass:"text-xs font-weight-semibold",attrs:{"x-small":"","elevation":"10","color":"primary darken-2","to":{ name: 'savings.clients_create_zone' }}},[_c('font-awesome-icon',{staticClass:"pe-1",attrs:{"icon":"fa-solid fa-plus"}}),_vm._v(" Create a Zone ")],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',{staticClass:"mt-0 pt-0"},[_c('span',{staticClass:"me-3"},[_vm._v("("+_vm._s(_vm.zone_types.length)+") List Of Zones")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.loading)?_c('v-card-text',[_c('spinner')],1):_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.zone_types,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.spot_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.spot_name)+" ")]}},{key:"item.city",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.city)+" ")]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.country)+" ")]}},{key:"item.get_date_str",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.get_date_str)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }